import * as React from "react";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import PropTypes from "prop-types";
import Header from "./header";
import "../styles/layout.css";

function Layout({ children }) {
  const { active, account } = useWeb3React();
  const [isENS, setIsENS] = React.useState(false);
  const [owner, setOwner] = React.useState(undefined);

  const getOwner = React.useCallback(async () => {
    const provider = new ethers.providers.JsonRpcProvider("https://mainnet.infura.io/v3/1533ece1f939473c91ba21f3ea0d055f");
    const fetchedENS = await provider.lookupAddress(account);
    if (fetchedENS) {
      setOwner(fetchedENS);
      setIsENS(true);
    } else {
      setOwner(account);
    }
  });

  React.useEffect(() => {
    if (active && owner === undefined) {
      getOwner();
    }
  }, [active]);

  return (
    <div className="root text-white font-clashgrotesk-regular">
      <div
        style={{
          margin: `0 auto`,
          maxWidth: `var(--size-content)`,
          padding: `var(--size-gutter)`,
        }}
      >
        <Header owner={owner} isENS={isENS} />
        <main>{ React.cloneElement(children, { owner: owner }) }</main>
      </div>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
