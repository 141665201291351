import * as React from "react";
import DummyItem from "./DummyItem";

function DummyGrid() {
  const getDummyArray = () => {
    let dummyArray;
    if (window.innerWidth >= 1536) {
      dummyArray = [1, 2, 3, 4];
    } else if (window.innerWidth >= 768) {
      dummyArray = [1, 2, 3];
    } else if (window.innerWidth >= 640) {
      dummyArray = [1, 2];
    }
    else dummyArray = [1];
    return dummyArray;
  };

  return (
    <div className="grid sm:grid-cols-1 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 w-full py-4 px-2 gap-y-14 justify-items-center">
      {getDummyArray().map((element, index) => (
        <DummyItem key={element} index={index} />
      ))}
    </div>
  );
}

export default DummyGrid;
