import * as React from "react";
import CollectionItem from "./CollectionItem";

function CollectionGrid({ collection }) {
  return (
    <div className="grid sm:grid-cols-1 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 w-full py-4 px-2 gap-y-14 justify-items-center">
      {collection.map((nft, index) => (
        <CollectionItem
          owner={nft.owner}
          isENS={nft.isENS}
          key={nft.name}
          id={index}
          name={nft.name}
          image={nft.featuredImg}
        />
      ))}
    </div>
  );
}

export default CollectionGrid;
