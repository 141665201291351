import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useWeb3React } from "@web3-react/core";
import { injected } from "../web3/connector";
import Layout from "../components/layout";
import CollectionGrid from "../components/Collection/CollectionGrid";
import DummyGrid from "../components/Collection/DummyGrid";
import useEagerConnect from "../hooks/useEagerConnect";

function BlocktalkWorld({ owner }) {
  const data = useStaticQuery(graphql`
  query nftQuery {
    allNftMedia {
      edges {
        node {
          featuredImg {
            childImageSharp {
              gatsbyImageData
            }
          }
          owner
          isENS
          name
        }
      }
    }
  }
`);

  const tried = useEagerConnect();
  const [nftCollection, setNftCollection] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);

  const {
    activate, active, account,
  } = useWeb3React();

  const saveNFTs = React.useCallback(async () => {
    const receivedNFTs = [];

    const nftsFromQuery = data.allNftMedia.edges;

    nftsFromQuery.forEach((item, index) => {
      receivedNFTs.push(item.node);

      if (index === nftsFromQuery.length - 1) {
        setTimeout(() => setNftCollection(receivedNFTs), 2000);
        setTimeout(() => setLoaded(true), 1000);
      }
    });
  });

  async function connect() {
    await activate(injected);
  }

  React.useEffect(() => {
    saveNFTs();
  }, []);

  const renderConnectWalletButton = () => {
    return (
      <button
        onClick={async () => {
          await connect();
        }}
        className="h-10 my-auto border-2 border-gray-300 px-2 py-1 rounded-lg"
        type="button"
      >
        Connect Wallet
      </button>
    );
  };

  const renderDummyGrid = () => <DummyGrid />;

  const renderFetchedNFTs = () => {
    if (nftCollection.length > 0) { return <CollectionGrid collection={nftCollection} />; }
    return <DummyGrid />;
  };

  const renderYourNFTs = () => {
    const yourNFTs = [];

    nftCollection.forEach((nft) => {
      if (nft.owner === owner) {
        yourNFTs.push(nft);
      }
    });

    if (!loaded) return renderDummyGrid();
    if (yourNFTs.length === 0) {
      return (
        <div className="flex justify-center align-center py-20">
          <span className="mt-3 text-3xl font-bold"> No NFTs found </span>
        </div>
      );
    }
    return <CollectionGrid collection={yourNFTs} />;
  };

  const renderYourSection = () => {
    if (nftCollection.length === 0) return renderDummyGrid();
    return renderYourNFTs();
  };

  return (
    <div>
      <div className="px-8 py-2">
        <div className="w-auto flex flex-col space-y-20">
          <div className="flex justify-center w-full">
            <div className="text-5xl font-clashgrotesk-bold">BT World</div>
          </div>
          <div className="flex flex-col space-y-6 my-12">
            <div className="text-4xl font-clashgrotesk-semibold">
              Your NFTs
            </div>
            <div
              className={`flex justify-center w-full border-2 border-gray-400 rounded-lg px-4  ${
                active ? "" : "py-20"
              } ${!tried ? "h-32" : "  "}`}
            >
              {!active
                ? renderConnectWalletButton()
                : renderYourSection()}
            </div>
          </div>
          <div className="flex flex-col space-y-6">
            <div className="text-4xl font-clashgrotesk-semibold">
              View Full Collection
            </div>
            <div className="w-auto border-2 border-gray-400 rounded-lg px-2 py-2">
              {renderFetchedNFTs()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Index() {
  return (
    <Layout>
      <BlocktalkWorld />
    </Layout>
  );
}

export default Index;
