import * as React from "react";

const animationClasses = {
  "0": "animate-pulse-1",
  "1": "animate-pulse-2",
  "2": "animate-pulse-3",
  "3": "animate-pulse-4",
};

function DummyItem({ index }) {
  return (
    <div className={`bg-gray-400 ${animationClasses[index]} cards rounded-lg space-y-2 h-80 sm:w-64 w-52`} />
  );
}

export default DummyItem;
