import { useWeb3React } from "@web3-react/core";
import * as React from "react";
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';
import { StaticImage } from "gatsby-plugin-image";
import truncateEthAddress from "truncate-eth-address";
import { injected } from "../web3/connector";

function Header({ owner, isENS }) {
  const { active, activate, account } = useWeb3React();

  async function connect() {
    await activate(injected);
  }

  return (
    <header>
      <div className="w-full px-8 py-6">
        <div className="flex w-full justify-end">
          <div className="flex flex-row space-x-5">
            {!active ? (
              <button
                onClick={async () => {
                  await connect();
                }}
                className="border-2 border-gray-300 px-2 py-1 rounded-lg"
                type="button"
              >
                Connect Wallet
              </button>
            ) : (
              <div className="flex flex-row space-x-2 border-2 border-gray-300 px-2 py-1 rounded-lg">
                <StaticImage
                  className="h-4 w-4 rounded my-auto"
                  src="https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png"
                  alt="matic"
                />
                <div className="my-auto">{isENS ? owner : truncateEthAddress(account)}</div>
                <div className="mt-0.5">
                  <Jazzicon diameter={24} seed={jsNumberForAddress(account)} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
