import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Transition } from "@headlessui/react";

function CollectionItem({ id, name, image, owner, isENS }) {
  const [showOption, setShowOption] = React.useState(false);

  return (
    <div
      onMouseEnter={() => setShowOption(true)}
      onMouseLeave={() => setShowOption(false)}
      className="flex overflow-hidden relative z-10 drop-shadow-xl shadow-indigo-500/50 flex-col h-80 sm:w-72 w-52 cards rounded-lg bg-white pb-1 space-y-2"
    >
      <a
        href={`https://opensea.io/assets/matic/0xd6c67d6caeb4a96db7b7fb7486abe7e2104714af/${id}`}
        target="_blank"
        rel="noopener noreferrer"
        className="overflow-hidden rounded-t-lg h-72 transform ease-in duration-200 "
      >
        <GatsbyImage
          onMouseEnter={() => setShowOption(true)}
          onMouseLeave={() => setShowOption(false)}
          className="transform ease-in-out duration-200 scale-100 hover:scale-110 object-cover h-full sm:w-72 w-52"
          alt="nft"
          image={image.childImageSharp.gatsbyImageData}
        />
      </a>
      <div className="w-full text-left text-black px-5 text-xl py-3 font-clashgrotesk-semibold whitespace-nowrap truncate">
        {name}
      </div>
      <div className="flex flex-row px-5 justify-between font-clashgrotesk-light text-black py-2 w-full">
        <div>Owned by:</div>
        <div>{isENS ? owner : `${owner.substring(0, 8)}${String.fromCharCode(0x2026)}`}</div>
      </div>
      <Transition
        show={showOption}
        enter="transition duration-200 ease-in"
        enterFrom="opacity-0 translate-y-6"
        enterTo="opacity-100 translate-y-0"
        leave="transition duration-300 translate-y-0"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-6"
        className="absolute h-full w-full h-12 bottom-0 z-0"
      >
        <a
        href={`https://opensea.io/assets/matic/0xd6c67d6caeb4a96db7b7fb7486abe7e2104714af/${id}`}
        target="_blank" 
        rel="noopener noreferrer"
        className="absolute w-full bg-black rounded-b-md bottom-0 h-10 bg-blue-400 text-center pt-2.5 font-clashgrotesk-semibold no-underline"
        >
          {" "}
          <span className="pt-2">
            View on Open Sea
          </span>
        </a>
      </Transition>
    </div>
  );
}

export default CollectionItem;
